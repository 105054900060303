import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2_607)">
        <path
          d="M19.1152 15.2924C19.1152 19.4915 15.7472 22 11.5916 22C7.43589 22 4.54322 19.5187 4 16.3558H7.82974C8.25074 17.5556 9.62237 18.4281 11.5916 18.4281C13.778 18.4281 15.3534 17.3238 15.3534 15.3606C15.3534 13.3974 13.8188 12.3885 11.578 12.3885V15.1561C7.35441 15.1561 4.12223 12.7567 4.12223 8.74846C4.12223 4.74028 7.23217 2 11.6051 2C15.7472 2 18.5992 4.46763 18.8843 7.50785H15.1904C14.8917 6.41717 13.683 5.58554 11.6866 5.58554C9.2557 5.58554 7.91122 6.71711 7.91122 8.63939C7.91122 10.5617 9.45941 11.4888 11.578 11.4888V8.58487C16.1682 8.58487 19.1152 11.3388 19.1152 15.2924Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_607">
          <rect
            width="15.2174"
            height="20"
            fill="white"
            transform="translate(4 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
