import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="black"
      />
      <g clipPath="url(#clip0_2_484)">
        <path
          d="M18.0922 14.6339C18.0922 17.9932 15.3978 20 12.0733 20C8.74871 20 6.43458 18.015 6 15.4847H9.06379C9.40059 16.4445 10.4979 17.1425 12.0733 17.1425C13.8224 17.1425 15.0827 16.259 15.0827 14.6885C15.0827 13.1179 13.855 12.3108 12.0624 12.3108V14.5249C8.68353 14.5249 6.09778 12.6053 6.09778 9.39877C6.09778 6.19223 8.58574 4 12.0841 4C15.3978 4 17.6793 5.9741 17.9075 8.40628H14.9523C14.7133 7.53374 13.7464 6.86843 12.1493 6.86843C10.2046 6.86843 9.12897 7.77369 9.12897 9.31151C9.12897 10.8494 10.3675 11.591 12.0624 11.591V9.2679C15.7346 9.2679 18.0922 11.471 18.0922 14.6339Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_484">
          <rect
            width="12.1739"
            height="16"
            fill="white"
            transform="translate(6 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
