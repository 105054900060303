import { Balance } from "src/apis/wallet";
import _ from "lodash";
import BN from "bn.js";
import BigNumber from "bignumber.js";
import numeral from "numeral";
import { numToKorean, FormatOptions } from "num-to-korean";
import { TFunction } from "i18next";

import { translate } from "src/locales";
import { Blockchain, TransferType } from "src/__generate__/api";

export const TO_EIGHTEEN_FIXED = 18;
export const TO_EIGHT_FIXED = 8;
const TO_TWO_FIXED = 2;
export const BTC_DECIMAL = 8;
export const ETH_AND_KLAY_DECIMAL = 18;
export const EOS_DECIMAL = 4;

const NINE_DECIMAL_DIGITS = 10 ** 9;

const GWEI_DIGITS = NINE_DECIMAL_DIGITS;

const BASELINE_DECIMALS = 8;

export const PHONE_NUMBER_MAX_LENGTH = 14;

export const emptyToZero = (num?: string) => {
  return num ? num : "0";
};

const getOverFloatingBySymbol = ({
  blockchain,
  decimals,
  disabledFixed,
}: {
  blockchain: Blockchain;
  decimals?: number;
  disabledFixed?: boolean;
}) => {
  const byBlockchain: Record<Blockchain, number> = {
    [Blockchain.Bitcoin]: BTC_DECIMAL,
    [Blockchain.BitcoinTestnetV2]: BTC_DECIMAL,
    [Blockchain.BitcoinV2]: BTC_DECIMAL,
    [Blockchain.Ethereum]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.EthereumV2]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Klaytn]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.KlaytnV2]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Baobab]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Polygon]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Amoy]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.PolygonV2]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.OverTestnet]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Over]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Bsc]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.WemixTestnet]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.XplaTestnet]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Xpla]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Bizauto]: EOS_DECIMAL,
    [Blockchain.ArbitrumOne]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.StoryTestnet]: ETH_AND_KLAY_DECIMAL,
    [Blockchain.Story]: ETH_AND_KLAY_DECIMAL,
  };
  const res = decimals ?? byBlockchain[blockchain];
  if (disabledFixed) {
    return res;
  }
  return res > BASELINE_DECIMALS ? BASELINE_DECIMALS : res;
};

const getRegexOverFloatingBySymbol = ({
  blockchain,
  decimals,
  disabledFixed,
}: {
  blockchain: Blockchain;
  decimals?: number;
  disabledFixed?: boolean;
}) => {
  return new RegExp(
    `(\\.\\d{${getOverFloatingBySymbol({
      blockchain,
      decimals,
      disabledFixed,
    })}})\\d+`,
    "g",
  );
};

export const getDecimalDigitsByBlockchain = ({
  blockchain,
  decimals,
}: {
  blockchain: Blockchain;
  decimals?: number;
}) => {
  const byBlockchain: Record<Blockchain, number> = {
    [Blockchain.Bitcoin]: 10 ** BTC_DECIMAL,
    [Blockchain.BitcoinTestnetV2]: 10 ** BTC_DECIMAL,
    [Blockchain.BitcoinV2]: 10 ** BTC_DECIMAL,
    [Blockchain.Ethereum]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.EthereumV2]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Klaytn]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.KlaytnV2]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Baobab]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Polygon]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Amoy]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.PolygonV2]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.OverTestnet]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Over]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Bsc]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.WemixTestnet]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.XplaTestnet]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Xpla]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Bizauto]: 10 ** EOS_DECIMAL,
    [Blockchain.ArbitrumOne]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.StoryTestnet]: 10 ** ETH_AND_KLAY_DECIMAL,
    [Blockchain.Story]: 10 ** ETH_AND_KLAY_DECIMAL,
  };
  if (decimals !== undefined) {
    return 10 ** decimals;
  }
  return byBlockchain[blockchain];
};

export const amountToMultipleDecimalBN = ({
  amount,
  blockchain,
  decimals,
}: {
  amount: string;
  blockchain: Blockchain;
  decimals?: number;
}) => {
  const bigAmount = toBigNumber(amount).multipliedBy(
    getDecimalDigitsByBlockchain({ blockchain, decimals }),
  );
  return new BN(bigAmount.toFixed(0));
};

export const sumAmountAndEstimatedFee = ({
  amount,
  blockchain,
  estimatedFee,
}: {
  amount: string;
  blockchain: Blockchain;
  estimatedFee?: string;
}) => {
  const bigNumberEstimatedFee = toBigNumber(
    emptyToZero(emptyToZero(estimatedFee)),
  ).dividedBy(getDecimalDigitsByBlockchain({ blockchain }));
  return bigNumberEstimatedFee.plus(emptyToZero(amount));
};

export const isNotAvailableAmount = ({
  amount,
  includeZero,
}: {
  amount: string;
  includeZero?: boolean;
}) => {
  if (amount === "") {
    return true;
  }
  const bigNumberAmount = toBigNumber(emptyToZero(amount));
  if (includeZero) {
    return bigNumberAmount.isNegative();
  }
  return bigNumberAmount.isNegative() || bigNumberAmount.eq(0);
};

export const getPhoneNumber = (value: string) => {
  const targetNumber = value.replace(/[^0-9.]/g, "");
  return targetNumber;
};

export const getOverFloatingAndNotNumber = ({
  amount,
  blockchain,
  t,
  decimals,
  disabledFixed,
}: {
  amount: string;
  blockchain: Blockchain;
  t: TFunction;
  decimals?: number;
  disabledFixed?: boolean;
}): { filteredAmount: string; errorMessage: string } => {
  const regexOverFloating = getRegexOverFloatingBySymbol({
    blockchain,
    decimals,
    disabledFixed,
  });
  const targetNumber = amount
    .replace(/[^0-9.,]/g, "")
    .replace(/(\..*)\./g, "$1");
  const targetAmount = targetNumber.replace(regexOverFloating, "$1");
  const errorMessage = regexOverFloating.test(targetNumber)
    ? `${translate(
        ["utils", "number", "getOverFloatingAndNotNumber", "regexMessage1"],
        t,
      )}${getOverFloatingBySymbol({
        blockchain,
        decimals,
        disabledFixed,
      })}${translate(
        ["utils", "number", "getOverFloatingAndNotNumber", "regexMessage2"],
        t,
      )}`
    : !_.isEmpty(amount) && amount !== targetAmount
      ? translate(
          ["utils", "number", "getOverFloatingAndNotNumber", "regexMessage3"],
          t,
        )
      : "";
  return {
    filteredAmount: targetAmount,
    errorMessage,
  };
};

export const isLessThanDust = ({
  amount,
  min,
  blockchain,
}: {
  amount: number;
  min: number;
  blockchain: Blockchain;
}) => {
  return min > amount;
};

export const isLackBalanceAmount = ({
  amount,
  balanceAmount,
  blockchain,
  estimatedFee,
  decimals,
}: {
  amount: string;
  balanceAmount: string;
  blockchain: Blockchain;
  decimals?: number;
  estimatedFee?: string;
}) => {
  const bigNumberBalanceAmount = toBigNumber(balanceAmount).dividedBy(
    getDecimalDigitsByBlockchain({ blockchain, decimals }),
  );
  return bigNumberBalanceAmount
    .minus(
      sumAmountAndEstimatedFee({
        amount,
        blockchain,
        estimatedFee,
      }),
    )
    .isLessThan(0);
};

export const numberToCountFormat = (amount: number | string) => {
  return toBigNumber(amount).toFormat();
};

export const numberToAmountFormat = (amount: number | string | BigNumber) => {
  return toBigNumber(amount).toFormat();
};

export const numberToBalanceFormat = ({
  coin,
  blockchain,
  decimals,
  disabledFixed,
}: {
  coin: number | string | BN | BigNumber;
  blockchain: Blockchain;
  decimals?: number;
  disabledFixed?: boolean;
}) => {
  const res = toBigNumber(coin)
    .dividedBy(getDecimalDigitsByBlockchain({ blockchain, decimals }))
    .toFixed(
      getOverFloatingBySymbol({ blockchain, decimals, disabledFixed }),
      BigNumber.ROUND_DOWN,
    );
  return toBigNumber(res).toFormat();
};

export const numberToBalanceSignFormat = ({
  coin,
  blockchain,
  transferType,
  decimals,
}: {
  coin: number | string | BN | BigNumber;
  blockchain: Blockchain;
  transferType: TransferType;
  decimals: number;
}) => {
  const divideDecimalDigitCoin = toBigNumber(coin)
    .dividedBy(getDecimalDigitsByBlockchain({ blockchain, decimals }))
    .toFixed(
      getOverFloatingBySymbol({ blockchain, decimals }),
      BigNumber.ROUND_DOWN,
    );
  const bigNumberCoin = toBigNumber(divideDecimalDigitCoin);
  return `${
    transferType === TransferType.Deposit ? "+" : "-"
  } ${bigNumberCoin.toFormat()}`;
};

export const isZeroBalance = ({
  coin,
  blockchain,
  decimals,
}: {
  coin: number | string | BN | BigNumber;
  blockchain: Blockchain;
  decimals: number;
}) => {
  const bigNumberBalanceAmount = toBigNumber(coin).dividedBy(
    getDecimalDigitsByBlockchain({ blockchain, decimals }),
  );
  return bigNumberBalanceAmount.eq(toBigNumber(0));
};

export const numberToGasPriceBalanceFormat = ({
  gasPriceBalance,
  blockchain,
  fixed = TO_TWO_FIXED,
  ignoreFixed,
}: {
  gasPriceBalance: number | string | BN | BigNumber;
  blockchain: Blockchain;
  fixed?: number;
  ignoreFixed?: boolean;
}) => {
  const res = toBigNumber(gasPriceBalance).dividedBy(
    getDecimalDigitsByBlockchain({ blockchain }),
  );
  if (ignoreFixed) {
    return res.toFormat();
  }
  return toBigNumber(res.toFixed(fixed, BigNumber.ROUND_DOWN)).toFormat();
};

export const numberToGasPriceBalanceSignFormat = ({
  gasPriceBalance,
  blockchain,
  fixed = TO_TWO_FIXED,
  ignoreFixed,
  transferType,
}: {
  gasPriceBalance: number | string | BN | BigNumber;
  blockchain: Blockchain;
  fixed?: number;
  ignoreFixed?: boolean;
  transferType: TransferType;
}) => {
  return `${
    transferType === TransferType.Deposit ? "+" : "-"
  } ${numberToGasPriceBalanceFormat({
    gasPriceBalance,
    blockchain,
    fixed,
    ignoreFixed,
  })}`;
};

export const numberToGasPriceFormat = ({
  gasPrice,
  fixed = TO_TWO_FIXED,
}: {
  gasPrice: number | string | BN | BigNumber;
  fixed?: number;
}) => {
  const res = toBigNumber(gasPrice)
    .dividedBy(GWEI_DIGITS)
    .toFixed(fixed, BigNumber.ROUND_DOWN);
  return toBigNumber(res).toFormat();
};

export const isDiffTenPercentageGasPrice = ({
  gasPrice,
  currentGasPrice,
}: {
  gasPrice: string;
  currentGasPrice: string;
}) => {
  const bigNumberTenPercentageGasPrice = toBigNumber(gasPrice).plus(
    toBigNumber(gasPrice).multipliedBy(0.1),
  );
  const bigNumberCurrentGasPrice = toBigNumber(currentGasPrice);

  return bigNumberCurrentGasPrice
    .minus(bigNumberTenPercentageGasPrice)
    .isGreaterThanOrEqualTo(0);
};

export const toBigNumber = (amount: number | string | BN | BigNumber) => {
  const target = new BigNumber(String(amount));
  if (_.isNaN(target.toNumber())) {
    return new BigNumber(numeral(amount).value() as BigNumber.Value);
  }
  return target;
};

export const minusAmount = (
  amount1: number | string | BN | BigNumber,
  amount2: number | string | BN | BigNumber,
) => {
  return toBigNumber(amount1).minus(toBigNumber(amount2));
};
export const plusAmount = (
  amount1: number | string | BN | BigNumber,
  amount2: number | string | BN | BigNumber,
) => {
  return toBigNumber(amount1).plus(toBigNumber(amount2));
};

export const amountToHangulFormat = (amount: number | string) => {
  const moreThanThousand = toBigNumber(amount)
    .dividedToIntegerBy(10000)
    .multipliedBy(10000)
    .integerValue();
  const lessThanThousand = toBigNumber(amount).minus(moreThanThousand);
  const isBothAmount =
    moreThanThousand.isGreaterThan(0) && lessThanThousand.isGreaterThan(0);
  return `${
    moreThanThousand.isGreaterThan(0)
      ? numToKorean(moreThanThousand.toNumber(), FormatOptions.MIXED)
      : ""
  }${isBothAmount ? " " : ""}${
    lessThanThousand.isGreaterThan(0)
      ? numberToAmountFormat(lessThanThousand)
      : ""
  }`;
};

export const getBalanceAmount = ({ balance }: { balance?: Balance | null }) => {
  return String(balance?.withdrawableAmount ?? "0");
};
